<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Editar dados do agendamento</h2>
        <hr />
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="updateAgendamento">
            <div class="row">
              <div class="col-md-12">
                <v-text-field
                  disabled
                  type="text"
                  v-model="nomePaciente"
                  label="Paciente (Obrigatório)"
                ></v-text-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Data do agendamento"
                  rules="required"
                >
                  <v-text-field
                    type="date"
                    v-model="dataAgendamento"
                    label="Data do agendamento (Obrigatório)"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Horário início "
                  rules="required"
                >
                  <v-text-field
                    type="time"
                    step="1"
                    v-model="horarioInicio"
                    label="Horário Inicial (Obrigatório)"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo"
                  rules="required"
                >
                  <b-form-select
                    style="border-color: black"
                    v-model="tipo"
                    :error-messages="errors"
                    :options="options"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Especialidade"
                  rules="required"
                >
                  <b-form-select
                    style="border-color: black"
                    v-model="especialidade"
                    :error-messages="errors"
                    :options="options2"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <v-btn
              :disabled="invalid"
              @click="updateAgendamento"
              class="mr-4 mt-4 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Atualizar</span>
            </v-btn>

            <router-link to="/ListaAgendamentos" v-slot="{ href, navigate }">
              <a :href="href" class="menu-link" @click="navigate">
                <v-btn class="mt-4 textStyle" tile color="#1dd1a1">
                  <span style="color: white">Voltar</span>
                </v-btn>
              </a>
            </router-link>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});

export default {
  name: "visualizarUsuario",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  async created() {
    try {
      const { data } = await ambulatorio.indexAgendamento(
        this.$route.params.id
      );

      this.nomePaciente = data.paciente_agendado.nome;
      this.dataAgendamento = data.data;
      this.horarioInicio = data.horario_inicio + "";
      this.horarioFinal = data.horario_final + "";
      this.tipo = data.tipo;
      this.especialidade = data.especialidade_id;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      nomePaciente: "",
      dataAgendamento: "",
      horarioInicio: "",
      horarioFinal: "",
      tipo: null,
      especialidade: "",
      options: [
        { value: null, text: "Selecione um tipo (Obrigatório)" },
        { value: "Retorno", text: "Retorno" },
        { value: "Consulta", text: "Consulta" }
      ],
      options2: [
        { value: null, text: "Selecione uma especialidade (Obrigatório)" },
        { value: 1, text: "Medicina de família e comunidade" },
        { value: 2, text: "Neurologia" },
        { value: 3, text: "Cardiologia" },
        { value: 4, text: "Endocrinologia" },
        { value: 5, text: "Pediatria" },
        { value: 6, text: "Ginecologia" },
        { value: 7, text: "Obstetrícia" },
        { value: 8, text: "Reumatologia" },
        { value: 9, text: "Dermatologia" }
      ]
    };
  },

  methods: {
    updateAgendamento: async function() {
      try {
        const data = {
          agendamento: {
            data: this.dataAgendamento,
            horario_inicio: this.horarioInicio,
            horario_final: "",
            tipo: this.tipo,
            especialidade_id: this.especialidade
          }
        };

        await ambulatorio.updateAgendamento(this.$route.params.id, data);

        Swal.fire({
          title: "",
          text: "Edição de Agendamento realizada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });

        setTimeout(() => {
          this.$router.push({ name: "listaAgendamentos" });
        }, 1000);
      } catch (error) {
        Swal.fire({
          title: "",
          text:
            "Erro ao realizar edição de agendamento, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
        console.log(error);
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Editar dados do agendamento" }
    ]);
  }
};
</script>

<style>
.theme--light.v-messages {
  color: red !important ;
}
</style>
